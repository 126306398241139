import mixpanel from 'mixpanel-browser'

// regex pattern to match IP addresses (e.g., 10.0.0.1, 192.168.1.1, etc.)
const ipRegex = /^(?:\d{1,3}\.){3}\d{1,3}$/

const isWhitelisted =
  ['localhost', 'dev.laborup.com'].every(
    (blacklistedUrl) => !window.location.hostname.includes(blacklistedUrl)
  ) && !ipRegex.test(window.location.hostname)

// if we are on app.laborup.com, use the production mixpanel key, if not, use the dev key
if (isWhitelisted) {
  mixpanel.init('f74f9c12e96ae2f637bfa38accc67f53', {
    track_pageview: true,
    persistence: 'localStorage',
    record_mask_text_selector: '', // Prevents text masking for non-input elements during recording.
    record_sessions_percent: 100, // Activate session recording for 100% of users.
  })
} else {
  mixpanel.init('61aaae7a9642d984285a41c238a9b570', {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
  })
}
