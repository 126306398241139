import { useEffect } from 'react'

import Cal, { getCalApi } from '@calcom/embed-react'

type Props = {
  calLink: string
  onBookingSuccessful?: () => void
  prefill?: Record<string, string>
} & React.HTMLAttributes<HTMLDivElement>

const layout = 'month_view'

export function CalCom({
  calLink,
  prefill,
  style,
  onBookingSuccessful,
  ...props
}: Props) {
  useEffect(() => {
    async function renderCal() {
      // ? It's not clear what `namespace` is but this came from the official
      // ? `cal.com` setup instructions and we only have 15min calls for now.
      const cal = await getCalApi({ namespace: '15min' })

      cal('on', {
        action: 'bookingSuccessful',
        callback: () => {
          if (onBookingSuccessful) {
            onBookingSuccessful()
          }
        },
      })

      cal('ui', { layout })
    }

    renderCal()
  }, [onBookingSuccessful])

  return (
    <Cal
      // ? It's not clear what `namespace` is but this came from the official
      // ? `cal.com` setup instructions and we only have 15min calls for now.
      namespace="15min"
      calLink={calLink.replace(/.*cal.com\//, '')} // `calLink` is expected to not have the `https://cal.com/` prefix.
      config={{ ...prefill, layout, theme: 'light' }}
      style={{
        width: '100%',
        height: '100%',
        // Hack to prevent bottom bar in mobile iOS Safari from covering bottom of scheduling options.
        // This is probably too much space but it's better to be safe than sorry.
        marginBottom: '96px',
        ...style,
      }}
      {...props}
    />
  )
}
