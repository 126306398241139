import { useCallback, useEffect, useMemo, useState } from 'react'

import { ParsedResume } from 'types/graphql'

import { routes } from '@redwoodjs/router'
import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'

import { DisjointProgressBar, Text } from 'src/atoms'
import { CertificationsStep } from 'src/components/ResumeImport/CompleteProfile/steps/CertificationsStep/CertificationsStep'
import { EducationsStep } from 'src/components/ResumeImport/CompleteProfile/steps/EducationsStep/EducationsStep'
import { IndustriesStep } from 'src/components/ResumeImport/CompleteProfile/steps/IndustriesStep/IndustriesStep'
import { SkillsStep } from 'src/components/ResumeImport/CompleteProfile/steps/SkillsStep/SkillsStep'
import { WorkExperiencesStep } from 'src/components/ResumeImport/CompleteProfile/steps/WorkExperiencesStep/WorkExperiencesStep'

type CompleteProfileQuery = {
  workExperiencesCount: number
  educationsCount: number
  certificationsCount: number
}

export const QUERY: TypedDocumentNode<CompleteProfileQuery> = gql`
  query CompleteProfileQuery {
    workExperiencesCount: workExperiencesCount
    educationsCount: educationsCount
    certificationsCount: certificationsCount
  }
`

export const Loading = () => (
  <Text weight="semibold" className="text-sm text-gray-700">
    Loading...
  </Text>
)

// * Removed `Empty` because it should not be used in this context.

export const Failure = ({ error }: CellFailureProps) => (
  <div className="rw-cell-error">{error?.message}</div>
)

export const Success = ({
  parentDialogScrollableId,
  parsedResume,
  workExperiencesCount,
  educationsCount,
  certificationsCount,
}: CellSuccessProps<CompleteProfileQuery> & {
  parentDialogScrollableId: string
  parsedResume: ParsedResume
}) => {
  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
    const dialog = document.getElementById(parentDialogScrollableId)
    if (dialog) {
      dialog.scrollTop = 0
    }
  }, [currentStep, parentDialogScrollableId])

  const handleNext = useCallback(() => {
    setCurrentStep((prevStep) => prevStep + 1)
  }, [])

  const steps = useMemo(() => {
    const steps: React.ReactElement[] = []

    if (parsedResume.certifications?.length && certificationsCount === 0) {
      const certifications = parsedResume.certifications.map(
        (certification) => ({
          startMonth: certification?.startMonth || '',
          startYear: certification?.startYear || '',
          endMonth: certification?.endMonth || '',
          endYear: certification?.endYear || '',
          organization: certification?.organization || '',
          credentialId: certification?.credentialId || '',
          credentialUrl: certification?.credentialUrl || '',
          name: certification?.name || '',
        })
      )

      steps.unshift(
        <CertificationsStep
          certifications={certifications}
          handleNext={handleNext}
          isLastStep
        />
      )
    }

    if (parsedResume.educations?.length && educationsCount === 0) {
      const educations = parsedResume.educations.map((education) => ({
        endMonth: education?.endMonth || '',
        endYear: education?.endYear || '',
        current: education?.current || false,
        location: education?.location || '',
        startMonth: education?.startMonth || '',
        startYear: education?.startYear || '',
        school: education?.school || '',
        degree: education?.degree || '',
      }))

      steps.unshift(
        <EducationsStep
          educations={educations}
          handleNext={handleNext}
          isLastStep={!steps.length}
        />
      )
    }

    if (parsedResume.skills?.length) {
      const skills = parsedResume.skills
        .map((skill) => skill?.name)
        .filter((name) => typeof name === 'string')

      steps.unshift(
        <SkillsStep
          handleNext={handleNext}
          initialRecommendedSkillsNames={skills}
          isLastStep={!steps.length}
        />
      )
    }

    if (parsedResume.industries?.length) {
      const industries = parsedResume.industries
        .map((industry) => industry?.name)
        .filter((name) => typeof name === 'string')

      steps.unshift(
        <IndustriesStep
          handleNext={handleNext}
          initialRecommendedIndustriesNames={industries}
          isLastStep={!steps.length}
        />
      )
    }

    if (parsedResume.workExperiences?.length && workExperiencesCount === 0) {
      const workExperiences = parsedResume.workExperiences.map(
        (workExperience) => ({
          title: workExperience?.title || '',
          internalCompanyName: workExperience?.internalCompanyName || '',
          location: workExperience?.location || '',
          startMonth: workExperience?.startMonth || '',
          startYear: workExperience?.startYear || '',
          endMonth: workExperience?.endMonth || '',
          endYear: workExperience?.endYear || '',
          currentPosition: workExperience?.currentPosition || false,
          descriptions:
            workExperience?.descriptions?.filter(
              (description) => typeof description === 'string'
            ) || [],
        })
      )

      steps.unshift(
        <WorkExperiencesStep
          handleNext={handleNext}
          isLastStep={!steps.length}
          workExperiences={workExperiences}
        />
      )
    }

    return steps
  }, [
    handleNext,
    parsedResume,
    workExperiencesCount,
    educationsCount,
    certificationsCount,
  ])

  useEffect(() => {
    if (!steps.length) {
      window.location.replace(routes.profile())
    }
  }, [steps])

  return (
    <div className="flex flex-col gap-4">
      {steps.length > 1 && (
        <DisjointProgressBar
          currentStep={currentStep}
          totalSteps={steps.length}
        />
      )}
      {steps[currentStep]}
    </div>
  )
}
