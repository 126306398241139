import { useLazyQuery } from '@apollo/client'
import {
  InternalCompanyPredictionsQuery,
  InternalCompanyPredictionsQueryVariables,
} from 'types/graphql'

import { TypedDocumentNode } from '@redwoodjs/web'

const INTERNAL_COMPANY_PREDICTIONS_QUERY: TypedDocumentNode<
  InternalCompanyPredictionsQuery,
  InternalCompanyPredictionsQueryVariables
> = gql`
  query InternalCompanyPredictionsQuery(
    $input: InternalCompanyPredictionsInput!
  ) {
    internalCompanyPredictions: internalCompanyPredictions(input: $input) {
      name
    }
  }
`

export function useLazyGetInternalCompanyPredictions() {
  return useLazyQuery(INTERNAL_COMPANY_PREDICTIONS_QUERY)
}
