import React from 'react'

import { FieldValues, Path, Controller, PathValue } from 'react-hook-form'

import {
  InternalCompanySelect,
  InternalCompanySelectProps,
  Message,
} from 'src/atoms'
import { OptionalKeys } from 'src/types'

import { useFormContext } from './form'

export type RHFInternalCompanySelectProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  description?: React.ReactNode
  clearErrorOnChange?: boolean
  clearWarningOnChange?: boolean
} & Omit<InternalCompanySelectProps, 'value' | 'onChange'> &
  OptionalKeys<InternalCompanySelectProps, 'onChange'>

export const RHFInternalCompanySelect = <TFieldValues extends FieldValues>({
  name,
  description,
  clearErrorOnChange,
  clearWarningOnChange,
  onChange: handleChangeProps,
  onBlur: handleBlurProps,
  ...props
}: RHFInternalCompanySelectProps<TFieldValues>) => {
  const { control, clearErrors, clearWarning, warnings, setValue } =
    useFormContext<TFieldValues>()

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: {
          onChange: handleChangeField,
          onBlur: handleBlurField,
          ...field
        },
        fieldState: { error },
      }) => {
        const handleChange = (value: string) => {
          const stopPropagation = handleChangeProps?.(value)
          if (stopPropagation) {
            return
          }
          if (clearWarningOnChange) {
            clearWarning(name)
          }
          if (clearErrorOnChange) {
            clearErrors(name)
            setValue(name, value as PathValue<TFieldValues, Path<TFieldValues>>)
            return
          }
          handleChangeField(value)
        }

        const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
          const stopPropagation = handleBlurProps?.(e)
          if (stopPropagation) {
            return
          }
          handleBlurField()
        }

        const messageLabel = error?.message || warnings[name]
        const messageIntent = error?.message ? 'error' : 'warning'

        return (
          <>
            <InternalCompanySelect
              onChange={handleChange}
              onBlur={handleBlur}
              {...field}
              {...props}
              blurInputOnSelect
            />
            {description}
            <Message
              className="ms-1.5"
              label={messageLabel}
              intent={messageIntent}
            />
          </>
        )
      }}
    />
  )
}
